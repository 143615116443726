import * as React from "react";

function SvgFacebook(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.002 4.075c-9.203 0-16.665 7.462-16.665 16.665 0 8.317 6.093 15.21 14.062 16.465V25.558h-4.234V20.74H17.4v-3.672c0-4.18 2.488-6.485 6.293-6.485 1.823 0 3.733.325 3.733.325v4.099H25.32c-2.067 0-2.714 1.286-2.714 2.605v3.125h4.619l-.739 4.818h-3.88v11.647c7.969-1.249 14.062-8.144 14.062-16.462 0-9.203-7.462-16.665-16.665-16.665z"
        fill="#405A93"
      />
    </svg>
  );
}

export default SvgFacebook;
