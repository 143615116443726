import * as React from "react";

function SvgIconOpen(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25.967 18.75H14.033l1.484-1.67a1.25 1.25 0 00-1.867-1.66l-3.333 3.75a1.25 1.25 0 000 1.66l3.333 3.75a1.249 1.249 0 101.867-1.66l-1.484-1.67h11.934l-1.484 1.67a1.249 1.249 0 001.479 1.975c.15-.078.281-.185.388-.315l3.333-3.75.019-.02a1.245 1.245 0 00-.022-1.645l-3.328-3.745a1.25 1.25 0 00-1.869 1.66l1.484 1.67z"
        fill="#838383"
      />
      <path
        d="M29.583 5A5.417 5.417 0 0135 10.417v19.166A5.417 5.417 0 0129.583 35H10.417A5.417 5.417 0 015 29.583V10.417A5.417 5.417 0 0110.417 5h19.166zm2.917 5.417A2.917 2.917 0 0029.583 7.5h-.416v8.578l-1.57-1.766a2.908 2.908 0 00-.93-.699V7.5H13.333v6.113a2.91 2.91 0 00-.93.7l-1.57 1.765V7.5h-.416A2.917 2.917 0 007.5 10.417v19.166a2.918 2.918 0 002.917 2.917h.416v-8.578l1.57 1.766c.269.302.587.534.93.699V32.5h13.334v-6.113c.343-.164.661-.397.93-.7l1.57-1.767v8.58h.416a2.917 2.917 0 002.917-2.917V10.417z"
        fill="#838383"
      />
    </svg>
  );
}

export default SvgIconOpen;
