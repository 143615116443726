import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loader from '../components/Loader';

const HebeViewer = React.lazy(() => import('../Clients/Hebe/index'));
const ArmaniViewer = React.lazy(() => import('../Clients/Armani/index'));
const Routes: React.FC = () => {
  return (
    <Suspense fallback={<Loader visible={true} />}>
      <Switch>
        <Route exact path='/'>
          <HebeViewer />
        </Route>
        <Route exact path='/armani'>
          <ArmaniViewer />
        </Route>
        <Route exact path='/hebe'>
          <HebeViewer />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default Routes;
