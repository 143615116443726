import * as React from "react";

function SvgZoomOut(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
      <path d="M16 4c6.6 0 12 5.4 12 12s-5.4 12-12 12S4 22.6 4 16 9.4 4 16 4m0-2C8.3 2 2 8.3 2 16s6.3 14 14 14 14-6.3 14-14S23.7 2 16 2z" />
      <path d="M8 15h16v2H8z" />
      <path fill="none" d="M0 0h32v32H0z" />
    </svg>
  );
}

export default SvgZoomOut;
