import * as React from "react";

function SvgQuestionMark(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={28}
      height={28}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.416 12.59L15.409.584a1.995 1.995 0 00-2.82 0L.584 12.591a1.994 1.994 0 000 2.819l12.005 12.006a1.994 1.994 0 002.82 0L27.415 15.41a1.994 1.994 0 000-2.82zM13.999 22a1.5 1.5 0 110-2.998 1.5 1.5 0 010 2.998zm1.125-6.752v1.877h-2.25V13H15a1.875 1.875 0 000-3.75h-2a1.877 1.877 0 00-1.875 1.875v.5h-2.25v-.5a4.13 4.13 0 014.125-4.126h2a4.125 4.125 0 01.125 8.249z"
        fill="#292929"
      />
    </svg>
  );
}

export default SvgQuestionMark;
