import * as React from "react";

function SvgPinterest(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 3.238a17.5 17.5 0 00-6.375 33.75 16.551 16.551 0 010-5l2.063-8.75a6.313 6.313 0 01-.476-2.5c0-2.425 1.413-4.25 3.163-4.25a2.199 2.199 0 012.212 2.5c0 1.5-.95 3.75-1.45 5.825a2.5 2.5 0 002.6 3.163c3.1 0 5.5-3.288 5.5-8.013a6.911 6.911 0 00-7.312-7.125 7.573 7.573 0 00-7.9 7.6 6.775 6.775 0 001.25 3.988.55.55 0 01.125.5c-.138.55-.438 1.75-.488 1.987-.05.238-.262.388-.587.238-2.188-1.025-3.55-4.213-3.55-6.788 0-5.512 4.012-10.587 11.562-10.587 6.063 0 10.788 4.325 10.788 10.112 0 6.025-3.75 10.875-9.087 10.875a4.7 4.7 0 01-4.013-2l-1.087 4.163a19.438 19.438 0 01-2.175 4.587c1.7.503 3.465.747 5.237.725a17.5 17.5 0 000-35z"
        fill="#BC3430"
      />
    </svg>
  );
}

export default SvgPinterest;
