import * as React from "react";

function SvgEmail(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M33.334 7.405H6.666a3.343 3.343 0 00-3.333 3.333v20c0 1.834 1.5 3.334 3.333 3.334h26.666c1.834 0 3.334-1.5 3.334-3.334v-20c0-1.833-1.5-3.333-3.334-3.333zm-.667 7.083l-10.9 6.817a3.3 3.3 0 01-3.534 0l-10.9-6.817a1.418 1.418 0 01.408-2.622 1.416 1.416 0 011.093.222L20 19.072l11.167-6.984a1.418 1.418 0 111.5 2.4z"
        fill="#292929"
      />
    </svg>
  );
}

export default SvgEmail;
