import * as React from "react";

function SvgLens(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M14 9h-3V6H9.5v3h-3v1.5h3v3H11v-3h3V9z" fill="#fff" />
      <path
        d="M16.586 15A8.142 8.142 0 0018.5 9.75 8.25 8.25 0 1010.25 18a8.142 8.142 0 005.25-1.914l5.69 5.664 1.06-1.06L16.586 15zm-6.336 1.5A6.75 6.75 0 1117 9.75a6.758 6.758 0 01-6.75 6.75z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgLens;
