import * as React from "react";

function SvgAndroid(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <path
        fill="#3ddc84"
        d="M448 192c-17.6 0-32 14.4-32 32v128c0 17.6 14.4 32 32 32s32-14.4 32-32V224c0-17.6-14.4-32-32-32zm-384 0c-17.6 0-32 14.4-32 32v128c0 17.6 14.4 32 32 32s32-14.4 32-32V224c0-17.6-14.401-32-32-32zm48 176c0 26.51 21.49 48 48 48v64c0 17.6 14.4 32 32 32s32-14.4 32-32v-64h64v64c0 17.6 14.4 32 32 32s32-14.4 32-32v-64c26.51 0 48-21.49 48-48V192H112v176zM399.108 160.001c-4.858-43.942-29.502-81.896-64.81-104.823l16.012-32.023c3.952-7.903.748-17.514-7.155-21.466S325.64.941 321.689 8.845l-16.071 32.143-4.175-1.658C287.159 34.579 271.882 32 256 32c-15.881 0-31.158 2.579-45.444 7.328l-4.174 1.658-16.071-32.141c-3.952-7.904-13.564-11.106-21.466-7.156-7.904 3.952-11.107 13.563-7.156 21.466L177.7 55.178C142.392 78.104 117.748 116.059 112.889 160v16H400v-15.999h-.892zM208 128c-8.837 0-16-7.164-16-16 0-8.824 7.144-15.979 15.965-15.998l.047.001.026-.001C216.857 96.023 224 103.177 224 112c0 8.836-7.163 16-16 16zm96 0c-8.837 0-16-7.164-16-16 0-8.823 7.143-15.977 15.962-15.998l.025.001.048-.001C312.855 96.021 320 103.176 320 112c0 8.836-7.163 16-16 16z"
      />
    </svg>
  );
}

export default SvgAndroid;
