import * as React from "react";

function SvgTwitter(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 1.538C9.396 1.538.8 10.134.8 20.738s8.596 19.2 19.2 19.2 19.2-8.596 19.2-19.2-8.596-19.2-19.2-19.2zm7.81 15.728c.008.164.01.328.01.488 0 5-3.802 10.762-10.758 10.762a10.67 10.67 0 01-5.796-1.7c.294.036.596.05.902.05a7.574 7.574 0 004.696-1.618 3.79 3.79 0 01-3.532-2.624 3.8 3.8 0 001.706-.066 3.784 3.784 0 01-3.034-3.708v-.046c.51.282 1.094.454 1.714.474a3.78 3.78 0 01-1.17-5.052 10.753 10.753 0 007.794 3.954 3.782 3.782 0 016.444-3.45 7.593 7.593 0 002.4-.918 3.8 3.8 0 01-1.662 2.094 7.597 7.597 0 002.172-.598 7.666 7.666 0 01-1.886 1.958z"
        fill="#00ACEE"
      />
    </svg>
  );
}

export default SvgTwitter;
