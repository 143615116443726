import * as React from "react";

function SvgSpinner(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <path d="M192 64c0-35.346 28.654-64 64-64s64 28.654 64 64c0 35.346-28.654 64-64 64s-64-28.654-64-64zm203.497 192c0-28.996 23.507-52.503 52.503-52.503s52.503 23.506 52.503 52.503-23.507 52.503-52.503 52.503-52.503-23.507-52.503-52.503zm-51.285 135.765c0-26.263 21.29-47.553 47.553-47.553s47.553 21.29 47.553 47.553c0 26.263-21.29 47.553-47.553 47.553s-47.553-21.29-47.553-47.553zM212.931 448c0-23.786 19.282-43.069 43.069-43.069s43.069 19.282 43.069 43.069c0 23.786-19.282 43.069-43.069 43.069S212.931 471.787 212.931 448zM81.227 391.765c0-21.544 17.465-39.009 39.009-39.009s39.009 17.465 39.009 39.009-17.465 39.009-39.009 39.009-39.009-17.465-39.009-39.009zM28.669 256c0-19.513 15.818-35.331 35.331-35.331S99.331 236.487 99.331 256c0 19.513-15.818 35.331-35.331 35.331S28.669 275.513 28.669 256zm59.567-135.764c0-17.673 14.327-32 32-32s32 14.327 32 32c0 17.673-14.327 32-32 32s-32-14.327-32-32zm361.496 0c0 32.012-25.953 57.967-57.968 57.967-32.012 0-57.968-25.955-57.968-57.967 0-32.016 25.956-57.967 57.968-57.967 32.015 0 57.968 25.951 57.968 57.967z" />
    </svg>
  );
}

export default SvgSpinner;
